:root {
	--secondary: rgb(8, 141, 237);
	// Popup colors
	--popup-dark-blue: #0E1127;
	--popup-dark-gray: #343434;
	--popup-light-gray: #DCE1E4;
	--white: rgb(255, 255, 255);

}

.cookies-popup {
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 1000;
	display: none;

	&.active {
		display: flex;
		transition: all 0.3s ease;

		.cookies-popup__block {
			animation-duration: 0.6s;
			animation-name: transformPopupCookies;
		}
	}

	&__close {
		position: absolute;
		width: 13px;
		height: 13px;
		top: 15px;
		right: 14px;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: .3s ease;

		&::before,
		&::after {
			content: '';
			width: 100%;
			border-radius: 2px;
			height: 2px;
			position: absolute;
			background-color: var(--popup-dark-blue);
			transition: 0.3s ease-in-out all;
		}

		&::before {
			transform: rotate(-45deg);
		}

		&::after {
			transform: rotate(45deg);
		}

		&:hover {
			transition: all .3s ease;
			transform: scale(1.2) rotate(90deg);
		}
	}

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: transparent;
	}

	&__block {
		background-color: var(--white);
		position: absolute;
		box-shadow: 0 2px 10px var(--popup-dark-gray);
		border-radius: 4px;
		width: 270px;
		min-height: 300px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		bottom: 40px;
		right: 40px;
		transition: all 0.3s ease;

		h4 {
			margin-bottom: 10px;
			font-size: 15px;
			color: var(--black);
			font-weight: 600;


		}

		p {
			text-align: center;
			font-size: 14px;
			color: inherit;
			padding-bottom: 20px;

		}

		a {
			font-size: inherit;
			font-weight: inherit;
			position: relative;
			color: var(--secondary);

			&:before {
				content: "";
				transition: .3s ease;
				position: absolute;
				bottom: 0;
				right: 0;
				height: 2px;
				width: 0;
				background: var(--secondary);
			}

			&:hover {
				&:before {
					right: auto;
					left: 0;
					width: 100%;
				}
			}
		}

		&:before {
			content: "";
			background: url("../images/panel/cookies-logo.svg") no-repeat center;
			display: block;
			width: 96px;
			height: 96px;
			margin-bottom: 10px;
		}

		@include media(550) {
			left: 25px;
			right: 25px;
			bottom: 25px;
			width: auto;
		}
	}

	&__btn {
		font-size: 15px;
		background-color: var(--secondary);
		padding: 11px 40px;
		color: var(--white);
		border-radius: 6px;
		cursor: pointer;
		transition: 0.3s ease-in-out all;

		&:hover {
			transform: scale(1.1);
		}
	}

	@include media(550) {
		padding: 20px;
	}

	@keyframes transformPopupCookies {
		from {
			transform: translateX(150%) translateY(50%) scale(0.5) skewX(30deg);
			opacity: 0;
		}

		to {
			transform: translateX(0%) translateY(0) scale(1) skewX(0deg);
			opacity: 1;
		}
	}
}