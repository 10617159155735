/* Color auto generator
function */
body {
  position: relative;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  z-index: 9999999;
}
.popup-wrapper .popup {
  position: relative;
  right: unset;
  top: unset;
  left: unset;
  transform: unset !important;
}

.popup-fon {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 99999;
}

.popup-open .popup {
  display: flex;
}
.popup-open .popup-wrapper {
  display: flex;
}

.popup {
  display: none;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  right: auto;
  top: 50%;
  left: 50%;
  transform: translateX(0) translateY(-50%);
  transition: all 300ms ease-out;
  width: 100%;
  height: 761px;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  padding: 50px 10px 40px 87px;
  background: #ffffff;
  z-index: 99999;
  overflow-x: hidden;
}
@media only screen and (max-width: 1200px) {
  .popup {
    top: 50%;
    left: 50%;
    margin-top: 0;
  }
}
.popup .close-popup {
  position: absolute;
  right: 88px;
  top: 52px;
  font-size: 41px;
  color: #64c2c8;
  transition: all 300ms ease-out;
  cursor: pointer;
}
@media only screen and (max-width: 720px) {
  .popup .close-popup {
    right: 34px;
    top: 24px;
  }
}
.popup .close-popup:hover {
  color: black;
}
.popup .title-popup {
  margin-bottom: 20px;
  font-size: 50px;
  line-height: 130%;
  text-transform: uppercase;
  color: #64c2c8;
}
.popup .pf-flex {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media only screen and (max-width: 720px) {
  .popup .pf-flex {
    display: block;
  }
}
.popup .pf-flex .pf-column {
  width: 332px;
  margin-left: 38px;
}
@media only screen and (max-width: 720px) {
  .popup .pf-flex .pf-column {
    margin-left: 0;
  }
}
.popup .pf-flex .pf-column:first-child {
  margin-left: 0;
}

.popup-open .popup-fon {
  display: block;
}
.popup-open .popup {
  transform: translateX(-50%) translateY(-50%);
}
@media only screen and (max-width: 1200px) {
  .popup-open .popup {
    transform: translateX(-50%) translateY(-50%);
  }
}

.pf-row {
  margin-bottom: 18px;
}
.pf-row .pf-label {
  display: block;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 130%;
  color: #707070;
  font-weight: normal;
}
.pf-row .pf-text-field {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 0 17px;
  background: #ffffff;
  border: 1px solid #8b8b8b;
  font-size: 20px;
  line-height: 130%;
  color: #707070;
}
.pf-row .pf-text-field::-moz-placeholder {
  opacity: 1;
  color: #d3d3d3;
}
.pf-row .pf-text-field::-webkit-input-placeholder {
  opacity: 1;
  color: #d3d3d3;
}
.pf-row .pf-text-field:focus::-moz-placeholder {
  opacity: 0;
}
.pf-row .pf-text-field:focus::-webkit-input-placeholder {
  opacity: 0;
}

.checkBox {
  display: block;
  margin: 18px 0 42px 0;
  position: relative;
}
.checkBox input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.checkBox input:checked + .check-title:before {
  background: #64c2c8;
}
.checkBox .check-title {
  display: block;
  position: relative;
  padding-left: 40px;
  font-size: 20px;
  line-height: 130%;
  color: #707070;
  cursor: pointer;
}
.checkBox .check-title:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border: 1px solid #707070;
  background: #ffffff;
  transition: all 300ms ease-out;
}

.popup_btn,
a.popup_btn,
button.popup_btn {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  display: block;
  background: none;
  color: #64c2c8;
  border: #64c2c8 1px solid;
  padding: 15px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 20px;
  cursor: pointer;
}
.popup_btn:hover,
a.popup_btn:hover,
button.popup_btn:hover {
  background: #64c2c8;
  color: #ffffff;
}

/*-------------------------*/
.popup.popup_height_full {
  top: 0;
  bottom: 0;
  right: 0;
  left: unset;
  width: 756px;
  height: auto;
  margin-top: 0;
  padding: 20px 40px;
  justify-content: flex-start;
  transform: translateY(0);
}
.popup.popup_height_full .close-popup {
  right: 40px;
  top: 18px;
}
.popup .title-popup {
  font-size: 24px;
  font-weight: 600;
  color: #343434;
}
.popup .close-popup {
  right: 40px;
  top: 38px;
  font-size: 25px;
  color: #4f4f4d;
}

.popup_body {
  box-sizing: border-box;
  width: 100%;
}

.file_img_block {
  width: 100%;
  max-width: 502px;
  margin-bottom: 40px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.file_img_block input {
  position: absolute;
  left: -150px;
  top: 0;
  width: calc(100% + 150px);
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.file_img_block .file_img_btn {
  background: #f0eef0;
  border-radius: 4px;
  padding: 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  color: #272727;
  font-size: 15px;
  cursor: pointer;
}
.file_img_block .file_img_btn span {
  margin-right: 12px;
  cursor: pointer;
}

.img_grid_block {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 22px;
}
.img_grid_block > div {
  position: relative;
}
.img_grid_block > div:hover .igb-pic:before {
  opacity: 1;
}
.img_grid_block > div:hover .img_del {
  opacity: 1;
}
.img_grid_block > div .igb-pic {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 70%;
  cursor: pointer;
  border-radius: 4px;
}
.img_grid_block > div .igb-pic:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #fe6b00;
  border-radius: 4px;
  transition: all 300ms linear;
  box-sizing: border-box;
  opacity: 0;
}
.img_grid_block > div .igb-pic img {
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
}
.img_grid_block > div .img_del {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 16px;
  color: #272727;
  line-height: 1;
  cursor: pointer;
  transition: all 300ms linear;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  z-index: 2;
}

@media only screen and (max-width: 1024px) {
  .popup.popup_height_full {
    width: 100%;
  }
  .popup-center {
    width: 95%;
    min-height: auto;
    height: 90%;
    max-height: none;
    padding: 45px;
  }
  .popup-center .title-popup {
    margin-bottom: 30px;
  }
  .popup-center .checkBox {
    margin: 0;
  }
  .pc-inner {
    grid-gap: 20px;
  }
  .pc-field input {
    padding: 12px;
  }
  .pc-btn {
    padding: 12px;
  }
  .custom-file-upload {
    padding: 15px;
  }
}
@media only screen and (max-width: 720px) {
  .pc-captcha {
    transform: scale(0.8);
    transform-origin: 0;
  }
  .pc-captcha > div {
    width: 100%;
    height: 110px;
  }
  .pc-captcha .g-recaptcha {
    width: 100%;
    height: 110px;
  }
  .pc-captcha .g-recaptcha > div {
    width: 100% !important;
    height: 100% !important;
  }
  .pc-captcha iframe {
    width: 304px;
    height: 100%;
  }
}
@media only screen and (max-width: 550px) {
  .popup-center {
    width: 95%;
    height: 80%;
    padding: 20px 15px;
  }
  .popup-center .title-popup {
    margin-bottom: 40px;
    font-size: 18px;
  }
  .popup-center .checkBox {
    margin: 0;
    grid-column: 1;
  }
  .popup-center .checkBox .check-title {
    font-size: 15px;
  }
  .popup-center .close-popup {
    font-size: 20px;
    top: 20px;
    right: 15px;
  }
  .pc-inner {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .pc-field input {
    padding: 11px;
  }
  .pc-field .pc-label {
    margin-bottom: 7px;
  }
  .pc-label {
    font-size: 15px;
  }
  .pc-cv-field {
    grid-column: 1;
    margin-top: 10px;
  }
  .pc-btn {
    padding: 15px;
    margin-top: 20px;
  }
  .pc-captcha {
    grid-column: 1;
    transform-origin: center;
  }
  .pc-captcha .g-recaptcha > div > div {
    display: flex;
    justify-content: center;
  }
  .custom-file-upload {
    padding: 19px;
  }
}
@media only screen and (max-width: 420px) {
  .popup.popup_height_full {
    padding: 40px 20px;
  }
  .img_grid_block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .pc-captcha {
    transform: scale(0.7);
    overflow: hidden;
  }
  .pc-captcha > div {
    height: 110px;
  }
  .pc-captcha .g-recaptcha {
    height: 110px;
  }
  .pc-captcha iframe {
    width: 300px;
  }
}
.popup-center {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 840px;
  min-height: 500px;
  height: max-content;
  max-height: 90%;
  padding: 60px 70px;
  overflow: auto;
  overflow-x: hidden;
  justify-content: normal;
  align-items: normal;
}
.popup-center::-webkit-scrollbar {
  width: 5px;
}
.popup-center::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
}
.popup-center::-webkit-scrollbar-thumb {
  background: #343434;
  border-radius: 5px;
}
.popup-center::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.popup-center .title-popup {
  margin-bottom: 50px;
}
.popup-center .popup-form {
  width: 100%;
}
.popup-center .checkBox {
  grid-column: 1/span 2;
  margin: 10px 0 16px;
}
.popup-center .checkBox .check-title {
  padding-left: 30px;
  font-size: 16px;
  color: #343434;
}
.popup-center .checkBox .check-title:before {
  width: 18px;
  height: 18px;
  border: 3px solid #2d303f;
  top: 0;
  bottom: 0;
  margin: auto;
}
.popup-center .checkBox .check-title a {
  color: #64c2c8;
  text-decoration: none;
  position: relative;
}
.popup-center .checkBox .check-title a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #64c2c8;
  transition: 0.3s ease-in-out all;
}
.popup-center .checkBox .check-title a:hover:before {
  width: 100%;
}
.popup-center .checkBox input:checked + .check-title:before {
  background: url("./../images/panel/checkbox-check.svg") no-repeat center;
  background-size: 20px;
}

.pc-inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  width: 100%;
}
.pc-field {
  display: flex;
  flex-direction: column;
}
.pc-field .pc-label {
  margin-bottom: 10px;
}
.pc-field input {
  width: 100%;
  border: 1px solid #dce1e4;
  border-radius: 5px;
  padding: 16px;
  font-size: 15px;
  color: #343434;
}
.pc-field input:-webkit-autofill, .pc-field input:-webkit-autofill:hover, .pc-field input:-webkit-autofill:focus, .pc-field input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-text-fill-color: #343434;
}
.pc-cv-field {
  display: flex;
  flex-direction: column;
  grid-column: 1/span 2;
}
.pc-cv-field input[type=file] {
  display: none;
}
.pc-cv-field .cv_file_name {
  color: #64c2c8;
}
.pc-label {
  font: 16px/130% "BigCityGrotesquePro", sans-serif;
  color: #343434;
}
.pc-btn {
  font: 16px/130% "BigCityGrotesquePro", sans-serif;
  background-color: #0e1127;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  position: relative;
}
.pc-btn:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 4px solid hsl(236deg, 32%, 26%);
  transform-origin: center;
  transform: scale(1);
  border-radius: 5px;
}
.pc-btn:hover:before {
  transition: all 1s ease-in-out;
  transform-origin: center;
  transform: scale(1.2);
  opacity: 0;
}
.pc-captcha {
  grid-column: 1/span 2;
}

.custom-file-upload {
  border: 1px dashed #dce1e4;
  border-radius: 5px;
  padding: 19px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out all;
}
.custom-file-upload span {
  transition: 0.3s ease-in-out all;
}
.custom-file-upload:hover {
  border: 1px solid #0e1127;
  background-color: #0e1127;
}
.custom-file-upload:hover span {
  color: #ffffff;
}

.popup-open .popup-center {
  transform: unset;
}

@media only screen and (max-width: 1024px) {
  .popup-center {
    width: 95%;
    min-height: auto;
    height: 90%;
    max-height: none;
    padding: 45px;
  }
  .popup-center .title-popup {
    margin-bottom: 30px;
  }
  .popup-center .checkBox {
    margin: 0;
  }
  .pc-inner {
    grid-gap: 20px;
  }
  .pc-field input {
    padding: 12px;
  }
  .pc-btn {
    padding: 12px;
  }
  .custom-file-upload {
    padding: 15px;
  }
}
@media only screen and (max-width: 720px) {
  .pc-captcha {
    transform: scale(0.8);
    transform-origin: 0;
  }
  .pc-captcha > div {
    width: 100%;
    height: 110px;
  }
  .pc-captcha .g-recaptcha {
    width: 100%;
    height: 110px;
  }
  .pc-captcha .g-recaptcha > div {
    width: 100% !important;
    height: 100% !important;
  }
  .pc-captcha iframe {
    width: 304px;
    height: 100%;
  }
}
@media only screen and (max-width: 550px) {
  .popup-center {
    width: 95%;
    height: 80%;
    padding: 20px 15px;
  }
  .popup-center .title-popup {
    margin-bottom: 40px;
    font-size: 18px;
  }
  .popup-center .checkBox {
    margin: 0;
    grid-column: 1;
  }
  .popup-center .checkBox .check-title {
    font-size: 15px;
  }
  .popup-center .close-popup {
    font-size: 20px;
    top: 20px;
    right: 15px;
  }
  .pc-inner {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .pc-field input {
    padding: 11px;
  }
  .pc-field .pc-label {
    margin-bottom: 7px;
  }
  .pc-label {
    font-size: 15px;
  }
  .pc-cv-field {
    grid-column: 1;
    margin-top: 10px;
  }
  .pc-btn {
    padding: 15px;
    margin-top: 20px;
  }
  .pc-captcha {
    grid-column: 1;
    transform-origin: center;
  }
  .pc-captcha .g-recaptcha > div > div {
    display: flex;
    justify-content: center;
  }
  .custom-file-upload {
    padding: 19px;
  }
}
@media only screen and (max-width: 360px) {
  .pc-captcha {
    transform: scale(0.7);
    overflow: hidden;
  }
  .pc-captcha > div {
    height: 110px;
  }
  .pc-captcha .g-recaptcha {
    height: 110px;
  }
  .pc-captcha iframe {
    width: 300px;
  }
}
.hidden {
  display: none !important;
}

.orange {
  color: #ff5500;
}

.list__ a {
  display: block;
  line-height: 46px;
  padding: 0 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.list__ a:nth-child(2n+1) {
  background-color: #f5f5f5;
}
.list__ a:hover {
  border-color: #ff5500;
}

/* --- Bug Tracker --- */
.scan__block {
  position: fixed;
  top: calc(100% - 200px);
  right: 12px;
  height: 120px;
  width: 120px;
  background-color: #5d5b5b;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
  font-family: sans-serif;
  font-size: 20px;
  /*overflow: hidden;*/
  white-space: nowrap;
  z-index: 9999999;
}
.scan__block:hover {
  /*width: 140px;*/
  /*overflow-y: auto;*/
  background-color: #be2a2a;
  color: #fff;
  cursor: pointer;
}

.report__button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* --- FLEX --- */
.flex-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-btw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-align-items: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.flex-around {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-align-items: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.flex-center {
  display: flex;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
}

.m6 {
  margin: 6px;
}

.mt6 {
  margin-top: 6px;
}

.ml6 {
  margin-left: 6px;
}

.mr6 {
  margin-right: 6px;
}

.mb6 {
  margin-bottom: 6px;
}

.mtb6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mlr6 {
  margin-left: 6px;
  margin-right: 6px;
}

.m10 {
  margin: 10px;
}

.mt10 {
  margin-top: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mr10 {
  margin-right: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mtb10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mlr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m12 {
  margin: 12px;
}

.mt12 {
  margin-top: 12px;
}

.ml12 {
  margin-left: 12px;
}

.mr12 {
  margin-right: 12px;
}

.mb12 {
  margin-bottom: 12px;
}

.mtb12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.mlr12 {
  margin-left: 12px;
  margin-right: 12px;
}

.m16 {
  margin: 16px;
}

.mt16 {
  margin-top: 16px;
}

.ml16 {
  margin-left: 16px;
}

.mr16 {
  margin-right: 16px;
}

.mb16 {
  margin-bottom: 16px;
}

.mtb16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mlr16 {
  margin-left: 16px;
  margin-right: 16px;
}

.m20 {
  margin: 20px;
}

.mt20 {
  margin-top: 20px;
}

.ml20 {
  margin-left: 20px;
}

.mr20 {
  margin-right: 20px;
}

.mb20 {
  margin-bottom: 20px;
}

.mtb20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mlr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.p6 {
  padding: 6px;
}

.ptb6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.p12 {
  padding: 12px;
}

.pt12 {
  padding-top: 12px;
}

.pl12 {
  padding-left: 12px;
}

.pr12 {
  padding-right: 12px;
}

.pb12 {
  padding-bottom: 12px;
}

.ptb12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.plr12 {
  padding-left: 12px;
  padding-right: 12px;
}

.lh44 {
  line-height: 44px;
}

/* --- OTHER --- */
.w100p {
  width: 100%;
}

.center {
  text-align: center;
}

.block {
  display: block !important;
}

.none {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.clear {
  clear: both;
}

.nos {
  -moz-user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.img__loading {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 16px);
}

.btn__ {
  position: relative;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  min-width: 30px;
  width: 30px;
  padding: 0 4px;
  text-align: center;
  border-radius: 7px;
  color: #515365;
}
.btn__:before {
  display: inline-block;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #515365;
}
.btn__ .img__loading {
  position: relative;
  top: inherit;
  left: inherit;
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.btn__:hover {
  cursor: pointer;
  background-color: #ff5500;
  color: white !important;
}
.btn__:hover:before {
  color: white !important;
}

.btn__circle:before {
  display: inline-block;
  width: 34px;
  line-height: 34px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #cdcdcd;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}
.btn__circle:hover:before {
  color: #ffffff;
  background-color: #506690;
}

.pop_form .pf_row {
  margin-bottom: 12px;
}
.pop_form .pf_row label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
  font-weight: lighter;
  align-items: center;
}
.pop_form .pf_row input[type=tel],
.pop_form .pf_row input[type=password],
.pop_form .pf_row input[type=text],
.pop_form .pf_row input[type=number] {
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #f9f9f9;
  border: none;
  padding: 0 15px;
  font-size: 15px;
  box-sizing: border-box;
  font-weight: lighter;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

/*---------------------------- Sections ----------------------------*/
.heading-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hf-title {
  color: #010b15;
  font-size: 1.5rem;
  font-weight: 500;
}
.hf-title span {
  font-size: 14px;
  font-weight: 600;
  color: #8d8f8e;
}

.section-row-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
}
.section-row-wrap .sr-icon-move {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 20px;
  margin-top: 9px;
  background: #ffa012;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  line-height: 1;
}

.section-row {
  width: 100%;
  min-width: 10px;
  max-width: 100%;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all 300ms linear;
}
.section-row:hover {
  border-color: #2662d6;
}
.section-row.active {
  border-color: #2662d6;
}
.section-row.active .sr-head .sr-arrow {
  transform: rotate(180deg) translate(0, 60%);
}
.section-row .sr-head {
  background: #fafafa;
  padding: 0 45px 0 12px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  color: #434343;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.section-row .sr-head .sr-icon {
  margin-right: 15px;
  width: 50px;
}
.section-row .sr-head .sr-icon img {
  display: block;
  max-width: 100%;
}
.section-row .sr-head .sr-arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 18px;
  font-size: 20px;
  line-height: 1;
  color: #434343;
  transition: all 300ms linear;
}
.section-row .sr-cont {
  display: none;
  padding: 15px 12px 10px 12px;
}

/*---------------------------- /Sections ----------------------------*/
.radio-pretty {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
  max-width: 500px;
}
.radio-pretty label {
  display: block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 0;
}
.radio-pretty label input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.radio-pretty label input:checked + .radio-pretty-title {
  border-color: #ff5500;
}
.radio-pretty label .radio-pretty-title {
  padding: 6px 12px;
  margin: 0;
  border: 2px solid #ebebeb;
  border-radius: 6px;
  cursor: pointer;
  transition: all 300ms linear;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.radio-pretty label .radio-pretty-title img {
  display: block;
  margin-right: 10px;
}
.radio-pretty label .radio-pretty-title:hover {
  background-color: #f6f6f6;
}

/*---------------------------- Reset Article Styles ----------------------------*/
.text__wr a {
  color: #00aca7 !important;
  text-decoration: none !important;
}
.text__wr ul li {
  margin: inherit !important;
}
.text__wr p {
  margin: inherit !important;
}
.text__wr span {
  font-size: inherit !important;
  margin: inherit !important;
  font-family: inherit !important;
}

.ui-widget.ui-widget-content {
  z-index: 11 !important;
}

:root {
  --secondary: rgb(8, 141, 237);
  --popup-dark-blue: #0E1127;
  --popup-dark-gray: #343434;
  --popup-light-gray: #DCE1E4;
  --white: rgb(255, 255, 255);
}

.cookies-popup {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: none;
}
.cookies-popup.active {
  display: flex;
  transition: all 0.3s ease;
}
.cookies-popup.active .cookies-popup__block {
  animation-duration: 0.6s;
  animation-name: transformPopupCookies;
}
.cookies-popup__close {
  position: absolute;
  width: 13px;
  height: 13px;
  top: 15px;
  right: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.cookies-popup__close::before, .cookies-popup__close::after {
  content: "";
  width: 100%;
  border-radius: 2px;
  height: 2px;
  position: absolute;
  background-color: var(--popup-dark-blue);
  transition: 0.3s ease-in-out all;
}
.cookies-popup__close::before {
  transform: rotate(-45deg);
}
.cookies-popup__close::after {
  transform: rotate(45deg);
}
.cookies-popup__close:hover {
  transition: all 0.3s ease;
  transform: scale(1.2) rotate(90deg);
}
.cookies-popup__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.cookies-popup__block {
  background-color: var(--white);
  position: absolute;
  box-shadow: 0 2px 10px var(--popup-dark-gray);
  border-radius: 4px;
  width: 270px;
  min-height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 40px;
  right: 40px;
  transition: all 0.3s ease;
}
.cookies-popup__block h4 {
  margin-bottom: 10px;
  font-size: 15px;
  color: var(--black);
  font-weight: 600;
}
.cookies-popup__block p {
  text-align: center;
  font-size: 14px;
  color: inherit;
  padding-bottom: 20px;
}
.cookies-popup__block a {
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  color: var(--secondary);
}
.cookies-popup__block a:before {
  content: "";
  transition: 0.3s ease;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: var(--secondary);
}
.cookies-popup__block a:hover:before {
  right: auto;
  left: 0;
  width: 100%;
}
.cookies-popup__block:before {
  content: "";
  background: url("../images/panel/cookies-logo.svg") no-repeat center;
  display: block;
  width: 96px;
  height: 96px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 550px) {
  .cookies-popup__block {
    left: 25px;
    right: 25px;
    bottom: 25px;
    width: auto;
  }
}
.cookies-popup__btn {
  font-size: 15px;
  background-color: var(--secondary);
  padding: 11px 40px;
  color: var(--white);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
}
.cookies-popup__btn:hover {
  transform: scale(1.1);
}
@media only screen and (max-width: 550px) {
  .cookies-popup {
    padding: 20px;
  }
}
@keyframes transformPopupCookies {
  from {
    transform: translateX(150%) translateY(50%) scale(0.5) skewX(30deg);
    opacity: 0;
  }
  to {
    transform: translateX(0%) translateY(0) scale(1) skewX(0deg);
    opacity: 1;
  }
}